import React from "react"
import Header from "./header"
import Footer from "./footer"
import LeftSideBar from "./leftsidebar"
import BreadCrumb from "./breadcrumb"
import Alert from '@mui/material/Alert';
import BidaskHelmet from "./head";

const Ample = ({explainerBlurb, currPageTitle, seoDescription, location, children}) => {
   
        const explainer = (explainerBlurb) ? <Alert severity="info">{explainerBlurb}</Alert> : <></>

        return (
        <>
        <BidaskHelmet pageTitle={currPageTitle} pageDescription={seoDescription} location={location} />

        <div id="main-wrapper" data-layout="vertical" data-navbarbg="skin5" data-sidebartype="full"
        data-sidebar-position="absolute" data-header-position="absolute" data-boxed-layout="full">
         
            <Header />
            <LeftSideBar />
      
            <div className="page-wrapper" style={{minHeight: '250px'}}>
                <BreadCrumb currPageTitle={currPageTitle}/>
                
                {explainer}
                
                <div className="container-fluid">
                    {/* Start Page Content */}
                    {children}
                    {/* End Page Content */}
                </div>

                <Footer />
            </div>
        </div>

        {/*"../plugins/bower_components/jquery/jquery.min.js"*/}
        <script src={`${process.env.GATSBY_CONTENT_SITE_URL}/js/jquery.min.js`}></script>

        {/* Bootstrap tether Core JavaScript 
       "../plugins/bower_components/popper.js/popper.min.js"*/}
        <script src={`${process.env.GATSBY_CONTENT_SITE_URL}/js/popper.min.js`}></script>
        {/*<script src=`bootstrap/dist/js/bootstrap.min.js`></script>*/}
        <script src={`${process.env.GATSBY_CONTENT_SITE_URL}/js/app-style-switcher.min.js`}></script>
        {/*-Wave Effects */}
        <script src={`${process.env.GATSBY_CONTENT_SITE_URL}/js/waves.js`}></script>
        {/*Menu sidebar */}
        <script src={`${process.env.GATSBY_CONTENT_SITE_URL}/js/sidebarmenu.min.js`}></script>
        {/*Custom JavaScript */}
        <script src={`${process.env.GATSBY_CONTENT_SITE_URL}/js/custom.min.js`}></script>

    </>
    );
  }

  export default Ample;

