
import React from "react"
import { Link } from "gatsby"
import {useSiteMetadata} from "../../branding";
import { OutboundLink } from "gatsby-plugin-google-gtag"

const Footer = () => {
    const siteMetadata = useSiteMetadata();
    return (
        <footer className="footer" style={{display: "flex", justifyContent: "center"}}>
        <table >
        <tbody>
            <tr>
                <td style={{width:'25%', textAlign:'left',  paddingTop: "0px"}}><Link to="/disclaimer/">Disclaimer</Link></td>
                <td style={{width:'25%', textAlign:'center',paddingTop: "0px"}}><Link to="/tos/">Terms</Link></td>
                <td style={{width:'25%', textAlign:'center',paddingTop: "0px"}}><Link to="/privacy/">Privacy</Link></td>
                <td style={{width:'25%', textAlign:'right', paddingTop: "0px"}}><Link to="/contactus/">Contact </Link></td>
            </tr>
            <tr>
                <td colSpan="4" style={{textAlign:'center',paddingTop: "0px"}}>
                    2024 © {siteMetadata.title} created by {siteMetadata.authorShort}
                </td>
            </tr>
        </tbody>
        </table>
        </footer>
);
    }

export default Footer;