import { Link } from "gatsby"
import React from "react"

export default class SideBar extends React.Component {
    render() { 
        return (
            <aside className="left-sidebar" data-sidebarbg="skin6">
            {/* Left Sidebar - style you can find in sidebar.scss  */}
            {/* Sidebar scroll*/}
            <div className="scroll-sidebar">
                {/* Sidebar navigation*/}
                <nav className="sidebar-nav">
                    <ul id="sidebarnav">
                        <li className="sidebar-item"> <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                            to="/" aria-expanded="false"><i className="fa fa-globe"
                                aria-hidden="true"></i><span className="hide-menu">Market Overview</span></Link></li>

                        
                        <li className="sidebar-item"> <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                to="/tickerreport/" aria-expanded="false">
                                <i className=" fas fa-book" aria-hidden="true"></i><span className="hide-menu">Ticker Report</span></Link>
                        </li>

                        <li className="sidebar-item"> <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                to="/maxpain/" aria-expanded="false"><i className="fas fa-bolt"
                                    aria-hidden="true"></i><span className="hide-menu">Max Pain</span></Link></li>
                        
                        <li className="sidebar-item"> <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                to="/optionpricer/" aria-expanded="false"><i className="fas fa-money-bill-alt"
                                    aria-hidden="true"></i><span className="hide-menu">Option Pricer</span></Link></li>
                        
                                    <li className="sidebar-item"> <Link  className="sidebar-link waves-effect waves-dark sidebar-link"
                                        to="/spreadfinder/" aria-expanded="false"><i className="fas fa-chart-line"
                                            aria-hidden="true"></i><span className="hide-menu">Spread Finder</span></Link></li>

                        <li className="sidebar-item"> <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                                to="/condorbuilder/" aria-expanded="false"><i className="fas fa-fighter-jet"
                                    aria-hidden="true"></i><span className="hide-menu">Condor Builder</span></Link></li>
                        
                        <li className="sidebar-item"> <Link className="sidebar-link waves-effect waves-dark sidebar-link"
                            to="/pcratios/" aria-expanded="false"><i className="fa fa-info-circle"
                                aria-hidden="true"></i><span className="hide-menu">Put/Call Ratios</span></Link></li>
                        
                        
                    </ul>

                </nav>
                {/* End Sidebar navigation */}
            </div>
            {/* End Sidebar scroll*/}
            {/* End Left Sidebar - style you can find in sidebar.scss  */}
            </aside>
        );
    }
}
