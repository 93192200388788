import { graphql,useStaticQuery } from "gatsby";

/*
    some content will come from sitemetadata. 
      rather than pollute pages that need this content with GraphQL,
        provide this content from here
*/
export const useSiteMetadata = () => {
    const data = useStaticQuery(graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            authorShort
            author
          }
        }
      }
    `)
  
    return data.site.siteMetadata
};

export default useSiteMetadata;
