import { Link } from "gatsby"
import React from "react"
import LogoBannerOTT from "../../images/banner-OTT.png";


const Header = () => {
  return (
  <header className='topbar' data-navbarbg="skin5"> 
    <nav className="navbar top-navbar navbar-expand-md navbar-dark"  >
      <div className="navbar-header" data-logobg="skin6" >
          {/* Logo */}
          <Link className="navbar-brand" to="/">
              {/* Logo icon */}
              <div className="row">
                <div className="col-lg-8 col-md-8 col-sm-8 col-xs-8" > {/*className="logo-icon">*/}
                  {/* Dark Logo icon */}
                  <img src={LogoBannerOTT} alt="homepage" style={{paddingLeft:"0px"}} />
                </div>
              </div>
          </Link>
          {/* End Logo */}
      </div>
      <div className="navbar-collapse collapse text-white font-italic" id="navbarSupportedContent" data-navbarbg="skin5">
          <p>
          &nbsp;&nbsp;&nbsp;&nbsp;{/*empty space*/}&nbsp;&nbsp;&nbsp;&nbsp;
            </p>   
      </div>
    </nav>
  </header>
);
}

export default Header;
